<template>
    <div id="PlReport">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridReport.vue'

export default {
    name:'PlReport',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            tableName: 'P&L',
            tableIcon: 'mdi-finance',
            tableDescription: 'Forneça uma descrição para esta tabela',
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ]
                }
            ]

        }
    }
}
</script>

<style scoped>
</style>